@import "./index.scss";


/* --------------------------------------------- */
/* -------------------- FOOTER ----------------- */
/* --------------------------------------------- */

.footer-section {
    @extend .section;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
    font-family: "Poppins-regular", sans-serif;

    .footer-links {
        margin: 20px 0 10px;

        a {
            color: #ffffff;
            margin: 0 20px;
            text-decoration: none;
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
        }

        a:hover {
            color: #7c85c1;
        }
    }

    p {
        color: #ffffff;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin: 8px 0;
    }

    .clickable {
        cursor: pointer;    
    }
    
    .clickable:hover {
        color: #7c85c1;
    }
}

/* ------------ END OF FOOTER ------------------ */

/* -----------------PRIVACY POLICY-------------------- */
.privacy-policy-text{
    color: #ffffff;
    text-align: center;
    margin-bottom: 10%;

}

/* --------------------------------------------- */
/* ---------------- NAVIGATION ----------------- */
/* --------------------------------------------- */

.navbar {
    @extend .section;
    padding-bottom: 0 !important;
    height: 132px;
    padding: 56px 0px 30px 0px;
    width: $sections-width;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo {
        width: 100px;
        height: 100px;
        cursor: pointer;
    }

    .hamburger {
        cursor: pointer;
        width: 40px;
        height: 40px;
    }
}

/* ------------- END OF NAVIGATION ------------- */

/* --------------------------------------------- */
/* ------------------ SIDEBAR ------------------ */
/* --------------------------------------------- */

.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 40%;
    min-width: 410px;
    height: 100%;
    background-color: $background-color;
    color: #ffffff;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    transition: right 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    z-index: 1002;
}

.sidebar.open {
    right: 0;
}

.close-btn {
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 10%;
    right: 10%;
}

.sidebar-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.6;
    background-color: #000000ba;
    z-index: 1001;
    left: 0;
    top: 0;
}

.overlay .sidebar-overlay-none {
    display: none;
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 24px;
    padding: 0 70px;

    a {
        display: block;
        margin: 10px 0;
        text-decoration: none;
        color: $white-color;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        cursor: pointer;
    }

    a:hover {
        color: #7c85c1;
    }
}

.graphic img {
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: 10%;
    right: 10%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #010b48;
    z-index: 1001;
}

/* ------------ END OF SIDEBAR ----------------- */

/* --------------------------------------------- */
/* ---------------- NEWS SECTION --------------- */
/* --------------------------------------------- */

.news-home-section {
    @extend .section;
    display: flex;
    position: relative;

    .home-bg {
        width: 80%;
        height: 80%;
        max-width: $img-max-width;
        max-height: $img-max-height;

        position: absolute;
        right: 10%;
        top: 5%;
        z-index: 1;
    }

    .home-text {
        width: 60%;
        height: 400px;
        max-height: 70vh;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
    }

    .home-text-el {
        display: block;
        font-weight: 700;
        align-items: center;
        color: #ffffff;
    }

    .home-text-underline {
        box-shadow: inset 0px -35px 0px 0px $alternative-color;
        width: fit-content;
    }

    .home-text-underline-main {
        box-shadow: inset 0px -35px 0px 0px var(--theme-color);
        width: fit-content;
    }

    .home-text-desc {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 20px;

        width: 592px;
        height: 72px;

        /* Inside auto layout */
        order: 0;
        align-self: stretch;

        /* Desktop/Body */
        font-style: normal;
        align-items: center;
        color: #ffffff;
    }

    .home-element-image {
        position: absolute;
        right: -10vw;
        bottom: 10vh;
        max-width: 440px;
        height: auto;
        width: 50vh;
        //height: 390px;
        z-index: 2;
    }
}

// Display all news
.news-section {
    @extend .section;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 50px;

    .news-section {
        margin: 0;
        width: 100% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    .container {
        width: 48%;
        height: 50%;
        display: flex;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        max-width: $img-max-width;
        max-height: $img-max-height;
        display: block;
        position: relative;
        z-index: 1;
    }

    .content-news {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .digital-img {
            width: 90%;
            height: 90%;
        }

        button {
            width: auto;
            //padding: 10px 20px;
            align-self: flex-start;
        }

        .info-text {
            height: 16px;
        }
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

// News details
.news-article-section {
    @extend .section;
    max-width: 822px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
    margin-top: 4rem;

    .container img {
        width: 100%;
        height: auto;
    }
}

// Separator
.news-seperator-section {
    @extend .section;
    max-width: 822px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;

    .container {
        margin: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
}

// Other news
.other-news-section {
    @extend .section;
    max-width: 822px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;

    .home-text {
        width: 60%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
    }

    .home-text-el {
        display: block;
        font-weight: 700;
        align-items: center;
        color: #ffffff;
    }

    .home-text-underline {
        box-shadow: inset 0px -35px 0px 0px $alternative-color;
        width: fit-content;
    }

    .home-text-underline-main {
        box-shadow: inset 0px -35px 0px 0px var(--theme-color);
        width: fit-content;
    }

    .content-news {
        padding: 1rem 0;
    }
}

.home-section > * {
    grid-column-start: 1;
    grid-row-start: 1;
}

/* ---------- END OF NEWS SECTION -------------- */

/* --------------------------------------------- */
/* ------------- ABOUT US SECTION -------------- */
/* --------------------------------------------- */

// Home
.about-us-section {
    @extend .section;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .home-bg {
        height: 42vw;
        max-height: 600px;
        position: absolute;
        right: min(16vw, calc(306px - 6vw));
        top: max(calc(300px - 20vw), 40px);
        z-index: 1;
    }

    .home-text {
        width: 60%;
        height: 600px;
        max-height: 70vh;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
    }

    .home-text-underline {
        box-shadow: inset 0px -35px 0px 0px $alternative-color;
        width: fit-content;
    }

    .home-text-underline-main {
        box-shadow: inset 0px -35px 0px 0px var(--theme-color);
        width: fit-content;
    }

    .home-element-image {
        position: absolute;
        right: -5vw;
        bottom: max(calc(300px - 20vw), 1vw);
        max-width: 440px;
        width: 30vw;
        z-index: 2;
    }
}

// about-us building solutions
.building-solutions-section {
    @extend .section;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 8%;
        margin-top: 4%;
        max-width: 824px;
    }

    .info-text {
        justify-content: center;
    }

    .sub-header {
        justify-content: center;
    }

    .benefits-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 100px 40px;
        margin-top: 50px;
    }

    .benefit {
        width: 48%;
        display: flex;
        flex-direction: row;

        .benefit-image {
            min-width: 250px;
            min-height: 250px;
            border: 5px solid $alternative-color;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 96px;
                height: 96px;
            }
        }

        .benefit-text {
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            //gap: 20px;
            transform: translate(-38px);
            width: 320px;
            min-width: 300px;
            

            h3 {
                font-weight: 700;
                font-size: 30px;
                line-height: 45px;
                margin: 0;
            }

            p {
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

/* ---------- END OF ABOUT US SECTION ------------ */

/* ----------------------------------------------------------------------------------- */
/* ------------------------------- MYNEXTID SECTIONS --------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* --------------------------------------------- */
/* --------------- HOME SECTION ---------------- */
/* --------------------------------------------- */
.home-section {
    @extend .section;
    display: flex;
    position: relative;

    .home-bg {
        /*width: 80%;
        height: 80%;
        max-width: $img-max-width;
        max-height: $img-max-height;

        position: absolute;
        //right: 3%;
        right:10%;
        top: 5%;
        z-index: 1;*/

        /*width: 80%;
        max-width: $img-max-width;*/
        height: 42vw;
        max-height: 600px;

        position: absolute;
        //right: 3%;
        //right:15%;
        //top: 5%;
        right: min(15vw, calc(306px - 6vw));
        top: max(calc(300px - 20vw), 40px);
    }

    .home-text {
        width: 60%;
        height: 70vh;
        max-height: 70vh;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
    }

    .home-text-el {
        display: block;

        /* Desktop/h1 */
        font-weight: 700;
        font-size: 64px;
        line-height: 96px;
        /* identical to box height */
        align-items: center;
        //padding: 0 10px;
        color: #ffffff;
    }

    .home-text-underline {
        box-shadow: inset 0px -35px 0px 0px $alternative-color;
        width: fit-content;
    }

    .home-text-underline-main {
        box-shadow: inset 0px -35px 0px 0px var(--theme-color);
        width: fit-content;
    }

    .home-link {
        width: fit-content;
        min-width: 200px;
    }

    .home-element-image {
        /*position: absolute;
        right: -15%;
        bottom: 15%;
        width: 440px;
        height: 390px;
        z-index: 2;*/

        position: absolute;
        //right: -120px;
        //bottom: 50px;
        right: -5vw;
        bottom: max(calc(300px - 20vw), 3vw);
        max-width: 420px;
        //height:auto;
        width: 30vw;
        //height: 390px;
        z-index: 2;
    }
}

.home-section > * {
    grid-column-start: 1;
    grid-row-start: 1;

    //outline: 1px solid red;
    //background-color: rgba(255, 255, 255, 0.4)
}

/* ----------- END OF HOME SECTION ------------- */

/* --------------------------------------------- */
/* --------- DIGITAL DOCUMENT SECTION ---------- */
/* --------------------------------------------- */

.digitalDoc-section {
    @extend .section;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 2rem;

    .container {
        width: 50%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .container-image {
        width: 90%;
        height: 50%;
        display: flex;
        position: relative;
        flex-direction: row-reverse;
    }

    .shadow-container {
        width: calc(90% - 10px);
        max-width: $img-max-width;
        max-height: $img-max-height;
        border: 5px solid $alternative-color;
        position: absolute;
        height: calc(100% - 10px);
        z-index: 1;
        margin-left: 10%;
        transform: translate(-10%, -10%);
    }

    img {
        width: 90%;
        max-width: $img-max-width;
        max-height: $img-max-height;
        display: block;
        position: relative;
        z-index: 1;
        padding-left: 10%;
    }

    .content-digital {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .digital-img {
            width: 90%;
            height: 90%;
        }

        button {
            width: auto;
            align-self: flex-start;
        }
    }
}

/* ------- END OF DIGITAL DOCUMENT SECTION ------- */

/* ----------------------------------------------- */
/* --------------- SHOWCASE SECTION -------------- */
/* ----------------------------------------------- */

.getStarted-section {
    @extend .section;
    color: #ffffff;
    overflow-x: hidden;

    .steps {
        display: flex;
        flex-direction: row;
        transition: transform 0.5s ease-in-out;
    }

    .grid-container {
        display: grid;
        //grid-template-rows: 180px 180px; /* Adjust row height as needed */
        //grid-template-columns: 180px 540px; /* Adjust column width as needed */

        grid-template-rows: 180px 580px;
        /* Adjust row height as needed */
        grid-template-columns: 40px 510px;
        /* Adjust column width as needed */
    }

    .item {
        display: flex;
        align-items: center;
        padding: 25px;
    }

    .slider-arrows.left {
        margin-right: 20px;
        height: 100% !important;
        margin: auto;
    }

    .slider-arrows.right {
        margin-left: 20px;
        height: 100% !important;
        margin: auto;
    }

    .arrow {
        background-color: hsla(0, 0%, 56%, 0.5);
        cursor: pointer;
    }
}

/* ------------ END OF SHOWCASE SECTION ---------- */

/* ----------------------------------------------- */
/* ------------- KEY BENEFITS SECTION ------------ */
/* ----------------------------------------------- */
.benefits-section {
    @extend .section;
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .container {
        align-self: center;
    }

    .benefits-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;
        margin-top: 50px;
    }

    .benefits-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 100px 0px;
        margin-top: 50px;
    }

    .benefit {
        width: 48%;
        display: flex;
        flex-direction: row;
        min-width: 560px;

        .benefit-image {
            min-width: 250px;
            min-height: 250px;
            border: 5px solid $alternative-color;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 96px;
                height: 96px;
            }
        }

        .benefit-text {
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            //gap: 20px;
            transform: translate(-38px);
            width: 350px;

            h3 {
                font-weight: 700;
                font-size: 30px;
                line-height: 45px;
                margin: 0;
            }

            p {
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

/* --------- END OF KEY BENEFITS SECTION --------- */

/* ----------------------------------------------- */
/* ----------- CREDENTIALING4U SECTION ----------- */
/* ----------------------------------------------- */
.cred-4-u-section {
    @extend .section;

    .cred-text {
        border: 5px solid $alternative-color;
        padding: 20px 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .small-sub-header,
    .smaller-sub-header {
        display: flex;
        margin-bottom: 20px;
        align-self: center;
    }
}
/* ------ END OF CREDENTIALING4U SECTION --------- */

/* ----------------------------------------------- */
/* ------------- ACCESS DOCS SECTION ------------- */
/* ----------------------------------------------- */
.how-dpp-works-section {
    @extend .section;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 8%;
    }

    .info-text {
        justify-content: center;
    }

    .sub-header {
        justify-content: center;
    }

    .desc {
        max-width: 600px;
    }

    .sections-container {
        margin-top: 50px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
    }

    //----------------- TEST
    .container-image {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        flex-direction: row-reverse;
        margin-top: 5%;
    }

    .shadow-container {
        width: calc(95% - 10px);
        border: 5px solid $alternative-color;
        position: absolute;
        height: calc(100% - 10px);
        z-index: 1;
        transform: translate(-5%, -10%);
    }

    img {
        width: 95%;
        height: auto;
        display: block;
        position: relative;
        z-index: 1;
    }
    // OLD CODE
    /*.image-container {
        text-align: center;
        color: #ffffff;

        .image-header {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
        }
    }

    .shadow-image-container {
        margin-top: 5%;
        position: relative;
        display: flex;
        max-width: 1160px;
        justify-content: center;
    }

    .shadow-container {
        width: 100%;
        height: 100%;

        border: 5px solid $alternative-color;
        position: absolute;
        bottom: 40px;
        left: -40px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .shadow-img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
    }*/
}

/* -------- END OF ACCESS DOCS SECTION ----------- */

/* ----------------------------------------------- */
/* ----------------- TECH SECTION ---------------- */
/* ----------------------------------------------- */

.tech-section {
    @extend .section;

    .tech-section-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .desc {
            max-width: 887px;
        }
    }

    .tech-section-logos {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
    }

    .tech-section-logos img {
        margin: 10px;
        width: fit-content;
    }

    img {
        width: 100%;
        display: block;
    }
}
/* ---------- END OF TECH SECTION -------------- */

/* ----------------------------------------------- */
/* ------------- SOLUTIONS SECTION --------------- */
/* ----------------------------------------------- */

.solutions-section {
    text-align: center;
    justify-content: center;

    .sub-header {
        width: 100%;
        text-align: center;
        height: 27px;
        justify-content: center;

        /* Desktop/h6 */
        ////font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;

        color: #ffffff;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .header {
        width: 942px;
        height: 72px;
        justify-content: center;
        /* Desktop/h2 */
        ////font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        /* identical to box height */
        display: flex;
        align-items: center;

        color: #ffffff;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .desc {
        width: $img-max-width;
        height: 48px;

        /* Desktop/Body */
        ////font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 2rem;

        color: #f4f4f4;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
    }

    .content-solution {
        width: 620px;
        padding-top: 10%;
        margin: auto;
        padding: 2rem;

        .digital-img {
            width: 90%;
            height: 90%;
            z-index: 2;
        }
    }

    .content {
        margin-top: 4rem;
        width: 100%;
        display: flex;
    }

    // Relevant styles
    .image-stack {
        display: grid;
        position: relative; // imperative for the overlapping to work
        grid-template-columns: repeat(12, 1fr);
    }

    .image-stack__item--bottom {
        //grid-column: 4 / -2;
        grid-column: 1 / span 10;
        grid-row: 1;
    }

    .image-stack__item--top {
        grid-row: 1;
        grid-column: 2 / span 10;
        padding-top: 10%; // slightly arbitrary, keeps proportion once resized
        z-index: 1; // tells the browser to make this image on top
    }

    .image-stack__item--bottom-right {
        //grid-column: 4 / -2;
        grid-column: 3 / span 10;
        grid-row: 1;
    }

    .image-stack__item--top-right {
        grid-row: 1;
        grid-column: 2 / span 10;
        padding-top: 10%; // slightly arbitrary, keeps proportion once resized
        z-index: 1; // tells the browser to make this image on top
    }

    img {
        width: 100%;
        display: block;
    }

    .text-section {
        text-align: left;
        justify-items: left !important;
        justify-content: left !important;

        .info-text {
            width: 148px;
            height: 27px;

            /* Desktop/h6 */
            //font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */
            display: flex;
            align-items: center;

            color: #ffffff;

            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        .sub-text {
            width: 100%;
            height: 72px;

            /* Desktop/h2 */
            //font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
            /* identical to box height */
            display: flex;
            align-items: left !important;
            justify-content: left !important;

            color: #ffffff;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .desc-text {
            width: $img-max-width;
            height: 120px;

            /* Desktop/Body */
            //font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: left !important;

            color: #f4f4f4;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 1;
        }

        .solution-logo {
            /* Logo mynext */

            width: 100px;
            height: 132px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }
}
/* ------- END OF SOLUTIONS SECTION ------------ */

/* --------------------------------------------- */
/* ------ TRUST BUILDING SOLUTIONS SECTION ----- */
/* --------------------------------------------- */

.trust-building-solution-section {
    @extend .section;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 2rem;

    .container {
        width: 50%;
        height: 50%;
        display: flex;
        position: relative;
    }

    .container-image {
        width: 90%;
        height: 50%;
        display: flex;
        position: relative;
        flex-direction: row-reverse;
    }

    .shadow-container {
        width: calc(90% - 10px);
        max-width: $img-max-width;
        max-height: $img-max-height;
        border: 5px solid $alternative-color;
        position: absolute;
        height: calc(100% - 10px);
        z-index: 1;
        margin-left: 10%;
        transform: translate(-10%, -10%);
    }

    img {
        width: 90%;
        max-width: $img-max-width;
        max-height: $img-max-height;
        display: block;
        position: relative;
        z-index: 1;
        padding-left: 10%;
    }

    .content-digital {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .digital-img {
            width: 90%;
            height: 90%;
        }

        button {
            width: auto;
            align-self: flex-start;
        }
    }
}

/* -- END OF TRUST BUILDING SOLUTIONS SECTION -- */

/* --------------------------------------------- */
/* ----------- CONTACT FORM SECTION ------------ */
/* --------------------------------------------- */

.contact-section {
    @extend .section;

    .sub-header {
        justify-content: center;
        text-align: center;
    }

    .green {
        color: $color-green;
    }

    .purple {
        color: $color-purple;
    }

    .small-header {
        @extend .sub-header;
        font-size: 30px;
    }

    .form-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .contact-form {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        width: 40%;
        min-width: 400px;

        .label-text {
            padding-bottom: 8px;
        }

        input {
            background-color: $alternative-color;
            color: #ffffff;
            gap: 8px;
            padding: 16px 24px;
            font-size: 18px;
            line-height: 27px;
            font-family: "Poppins Bold", sans-serif;
            border: none;
            margin-bottom: 20px;
        }

        textarea {
            background-color: $alternative-color;
            color: #ffffff;
            gap: 8px;
            padding: 16px 24px;
            font-size: 18px;
            line-height: 27px;
            font-family: "Poppins Bold", sans-serif;
            border: none;
            margin-bottom: 20px;
            resize: vertical;
        }

        textarea:focus-visible,
        input:focus-visible {
            outline: none;
            /* Remove outline for focus-visible */
        }

        input:focus-visible {
            outline: none;
            /* Remove outline for focus-visible */
        }
    }
}
/* ------- END OF CONTACT FORM SECTION ----------- */

#getStartedCredentials .mobile-hide {
    display: flex;
}

#getStartedCredentials .mobile-show {
    display: none;
}

//--------------------- SHOWCASE ---------------------//
.showcase-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.showcase-element {
    /* card government */

    /* Auto layout */
    padding: 0px;

    width: 520px;
    //height: 600px;
    height: 696px;
    margin: 1rem 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    .top {
        /* Frame 145 */

        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 8px;

        width: 520px;
        height: 408px;

        background: #8c72f7;

        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;

        .showcase-image {
            width: 30px;
            height: 30px;
            top: 20px;
            left: 220px;
            position: relative;
            cursor: pointer;
        }

        transition: 1s;
    }

    .top2 {
        transition: 1s;
        /*opacity 1;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 2s;*/

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 8px;
        height: 408px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;

        p {
            color: #ffffff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            margin: 0 26px 10px 26px;
        }

        .showcase-examples {
            display: block;
            text-align: left;
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            margin: 0 26px;
            align-self: baseline;
        }

        .showcase-image {
            width: 30px;
            height: 30px;
            top: 20px;
            left: 220px;
            position: relative;
            cursor: pointer;
        }
    }

    .showcase-top2 {
        display: flex;
        flex-direction: row;

        .showcase-el-mini {
            width: 235px;
            top: 0px;
            right: 130px;
            position: relative;
        }

        .showcase-image {
            left: 338px;
        }
    }

    .bottom {
        /* Frame 139 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px 24px;
        gap: 8px;

        //width: 520px;
        height: 64px;

        background: #343c6d;

        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        p {
            cursor: pointer;
            /* Desktop/h6 */
            ////font-style: normal;
            font-weight: 700;
            font-size: 18px;
            /*line-height: 27px;
      display: flex;
      align-items: center;*/
        }

        p:hover {
            color: inherit;
        }
    }

    .showcase-el-title {
        ////font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        /* identical to box height */
        display: flex;
        align-items: center;
        text-align: center;

        color: #ffffff;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
    }
}

//-------------- end of SHOWCASE ---------------------//

// -------------------------------------------------//
//----------- END OF MYNEXTID sections -------------//
// -------------------------------------------------//

/* Scroller */

.scroller {
    max-width: 100vw;
}

.scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.scroller[data-animated="true"] {
    overflow: hidden;
    /*-webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
    );*/
    //mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
    --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
    --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
    --_animation-duration: 60s;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}

/* --------------------------------------------- */
/* -------------- MEDIA VIEW  ------------------ */
/* --------------------------------------------- */

@media screen and (max-width: 1624px) {
    .news-home-section .home-element-image {
        right: 0vw;
        width: 30vw;
    }
}

/* EXCEPTION for about us */
@media screen and (max-width: 1245px) {
    .benefits-container {
        justify-content: center !important;
    }
}

@media screen and (max-width: 1024px) {
    // NEWS
    .news-section .container {
        width: 100% !important;
    }

    .news-section .content-news {
        width: 100% !important;
    }

    .news-section img {
        max-width: unset;
        max-height: unset;
    }

    .news-home-section .home-text-desc {
        width: auto;
        height: auto;
    }

    .news-section .content-news .info-text {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    // Navigation
    .navbar {
        padding: 10px 0px 30px 0px;

        .logo {
            width: 70px;
            height: 70px;
        }
    }

    .sidebar {
        min-width: unset;
        width: 100%;
        padding: 0;

        .close-btn {
            top: 5%;
        }

        .links {
            gap: 0;
            width: calc(100% - 32px);
            padding: 0 16px 0 16px;
            word-break: break-all;

            a {
                font-size: 34px;
                line-height: 51px;
            }
        }

        .graphic {
            img {
                bottom: 5%;
            }
        }
    }

    // Footer
    .footer-section {
        padding: 15% 0;

        .footer-links {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            padding-bottom: 20px;
        }
    }



    // Home
    .home-section {
        .home-bg {
            width: 80%;
            height: 80%;
            max-width: $img-max-width;
            max-height: $img-max-height;

            position: absolute;
            //right: 3%;
            right: 10%;
            top: 5%;
            z-index: 1;
        }

        .home-element-image {
            display: none;
        }
    }

    // NEWS
    .news-section .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .news-section .content-news button {
        width: 100%;
        align-self: flex-start;
    }
    .news-section .content-news {
        width: 100%;
    }

    .news-home-section .home-element-image {
        display: none;
    }

    // ABOUT US
    .about-us-section .home-element-image {
        display: none;
    }

    .about-us-section .home-text {
        width: 100%;
        max-height: unset;
        height: 200px;
    }

    .about-us-section .home-elements {
        width: 100%;
    }

    .about-us-section .home-bg {
        position: unset;
        width: 100%;
        height: auto;
    }

    .center{
        justify-content: center;
    }

    .showcase-main-box{
        width: 100% !important;
    }
}

@media screen and (max-width: 767px) {
    // For all elements
    .m-show {
        display: block !important;
    }

    .m-hide {
        display: none !important;
    }

    .btn-mynextid {
        width: 100% !important;
    }

    // Scroller
    .scroller {
        overflow: auto;
    }

    .scroller__inner {
        flex-wrap: nowrap;
    }

    // Home
    .home-section {
        flex-direction: column;
        align-items: center;

        .home-text {
            width: 100%;

            .home-text-el {
                font-size: 36px;
                line-height: 58px;
            }
        }

        .home-bg {
            position: unset;
            //width: 100%;
            padding: 10px 0;
        }

        .home-text-underline {
            box-shadow: inset 0px -22px 0px 0px $alternative-color;
        }

        .home-text-underline-main {
            box-shadow: inset 0px -22px 0px 0px var(--theme-color);
        }

        .home-link {
            width: 100%;
            //max-width: 310px;
        }
    }

    // Trust building Solutions
    // Digital Documents
    .trust-building-solution-section,
    .digitalDoc-section {
        flex-direction: column;
        padding-top: 10%;
        gap: 0;

        .container {
            width: 100%;
        }

        .content-digital {
            margin-top: 2rem;
            width: 90%;
        }
    }

    // Showcase ----------------------------------------
    #getStartedCredentials .mobile-hide {
        display: none;
    }

    #getStartedCredentials .mobile-show {
        display: flex;
        //flex-direction: column;
    }

    .showcase-element .top .showcase-image {
        z-index: 1000;
        left: calc(50% - 40px);
    }

    .showcase-element .showcase-top2 {
        width: 100%;
    }

    .showcase-element .showcase-top2 .showcase-el-mini {
        //right: 0;
        max-width: 80%;
        right: 0px;
    }

    .showcase-element .top2 .showcase-image {
        //left: 218px;
        z-index: 1000;
        //left: calc(50% - 40px);
    }
    .showcase-element .showcase-top2 .showcase-image {
        left: unset;
        left: calc(100% - 55px);
    }

    .showcase-element .bottom p {
        font-size: 14px;
    }

    .showcase-element .showcase-el-title {
        font-size: 30px;
    }

    .getStarted-section {
        .steps-container {
            overflow-x: auto;

            .steps {
                width: 200%;
            }

            .grid-container {
                grid-template-rows: 145px 145px;
                grid-template-columns: 145px 145px;

                padding-bottom: 20px;
            }
        }

        .steps-container::-webkit-scrollbar {
            width: 0;
        }

        .slider-arrows {
            /*width:48px;
            height: 100% !important;
            margin: auto;*/
            margin: auto 0 !important;

            .arrow {
                width: 48px;
                height: 48px;
            }
        }
    }

    // Access docs
    .how-dpp-works-section {
        padding-top: 8%;

        .shadow-image-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .shadow-img {
                width: 95%;
                //max-width: 327px;
                //padding-left: 5%;
            }

            .shadow-container {
                width: 95%;
                bottom: 16px;
                left: 0;
            }
        }
    }

    // Contact form
    .contact-section {
        .contact-form {
            min-width: unset;
            width: 100%;
            margin-top: 40px;
        }

        .small-header {
            font-size: 23px;
            line-height: 34.5px;
        }
    }
    .benefits-m-hide {
        display: none !important;
    }

    .benefits-m-show {
        display: block !important;
    }

    // Benefits
    .building-solutions-section,
    .benefits-section {
        align-items: center;
        .benefits-container {
            width: 80%;
            gap: unset;

            .benefit {
                width: 100%;
                min-width: unset;
                max-height: 420px;
                flex-direction: column;
                margin-bottom: 40px;

                .benefit-image {
                    width: 250px;
                    height: 250px;
                    img {
                        position: relative;
                        top: -30px;
                        left: -30px;
                    }
                }

                .benefit-text {
                    width: 90%;
                    transform: translate(0, -80px);
                    padding-left: 50px;
                    justify-content: flex-start;
                    height: unset;

                    h3 {
                        font-size: 23px;
                        line-height: 36px;
                    }

                    p {
                        margin: 6px 0;
                    }
                }
            }
        }
    }
}

/* @media screen and (max-width: 600px) {
    
} */

@media screen and (max-width: 425px) {
    // Showcase
    .showcase-main-box {
        width: 100% !important;
    }

    .showcase-element .showcase-top2 .showcase-el-mini {
        max-width: 80%;
        right: 0px;
        margin-top: 60px;
    }

    .getStarted-section .slider-arrows .arrow {
        width: 25px;
        height: 25px;
    }

    .showcase-element .top2 p {
        font-size: 12px;
    }
}
