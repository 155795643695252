@import "../../../../scss/index.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #010b48;
    z-index: 1001;
}

.dpp-theme {
    /* --------------------------------------------- */
    /* -------------------- FOOTER ----------------- */
    /* --------------------------------------------- */

    .footer-section {
        @extend .section;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 5%;
        font-family: "Poppins-regular", sans-serif;

        .footer-links {
            margin: 20px 0 10px;

            a {
                color: #ffffff;
                margin: 0 20px;
                text-decoration: none;
                font-size: 18px;
                line-height: 27px;
                font-weight: 400;
            }

            a:hover {
                color: #7c85c1;
            }
        }

        p {
            color: #ffffff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            margin: 8px 0;
        }

        .clickable {
            cursor: pointer;    
        }
        
        .clickable:hover {
            color: #7c85c1;
        }
    }

    /* ------------ END OF FOOTER ------------------ */

    /* --------------------------------------------- */
    /* ---------------- NAVIGATION ----------------- */
    /* --------------------------------------------- */

    .navbar {
        @extend .section;
        padding-bottom: 3% !important;
        height: 132px;
        padding: 56px 0px 30px 0px;
        width: $sections-width;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 100px;
            height: 132px;
            cursor: pointer;
        }

        .hamburger {
            cursor: pointer;
            width: 40px;
            height: 40px;
        }
    }
    /* ------------- END OF NAVIGATION ------------- */

    /* --------------------------------------------- */
    /* ------------------ SIDEBAR ------------------ */
    /* --------------------------------------------- */

    .sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        width: 40%;
        min-width: 410px;
        height: 100%;
        background-color: $background-color;
        color: #ffffff;
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
        transition: right 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        z-index: 1002;
    }

    .sidebar.open {
        right: 0;
    }

    .close-btn {
        border: none;
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: absolute;
        top: 10%;
        right: 10%;
    }

    .sidebar-overlay {
        position: absolute;
        width: 100vw;
        height: 100vh;
        opacity: 0.6;
        background-color: #000000ba;
        z-index: 1001;
        left: 0;
        top: 0;
    }

    .overlay .sidebar-overlay-none {
        display: none;
    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 24px;
        padding: 0 70px;

        a {
            display: block;
            margin: 10px 0;
            text-decoration: none;
            color: $white-color;
            font-size: 40px;
            font-weight: 700;
            line-height: 60px;
            cursor: pointer;
        }

        a:hover {
            color: #7c85c1;
        }
    }

    .graphic img {
        width: 150px;
        height: 150px;
        position: absolute;
        bottom: 10%;
        right: 10%;
    }

    /* ------------ END OF SIDEBAR ----------------- */

    // -------------------------------------------------//
    //---------------- DPP sections --------------------//
    // -------------------------------------------------//

    /* --------------------------------------------- */
    /* --------------- HOME SECTION ---------------- */
    /* --------------------------------------------- */
    .home-section {
        @extend .section;
        display: flex;
        position: relative;

        .home-bg {
            width: 80%;
            height: auto;
            max-width: $img-max-width;
            max-height: $img-max-height;

            position: absolute;
            right: 3%;
            top: 5%;
            z-index: 1;
        }

        .home-text {
            width: 60%;
            height: 70vh;
            max-height: 70vh;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 2;
        }
        .home-text-el {
            display: block;
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 96px;
            align-items: center;
            color: #ffffff;
        }

        .home-text-underline {
            box-shadow: inset 0px -35px 0px 0px #343c6d;
            width: fit-content;
        }

        .home-text-underline-main {
            box-shadow: inset 0px -35px 0px 0px $color-green;
            width: fit-content;
        }

        .home-link {
            width: fit-content;
            min-width: 200px;
        }

        .home-element {
            width: 38%;
            display: flex;
            flex-direction: column-reverse;
            margin-left: auto;
        }
        .home-element-image {
            height: 50%;
            width: fit-content;
            z-index: 2;
        }
    }

    .home-section > * {
        grid-column-start: 1;
        grid-row-start: 1;

        //outline: 1px solid red;
        //background-color: rgba(255, 255, 255, 0.4)
    }

    /* ----------- END OF HOME SECTION ------------- */

    /* --------------------------------------------- */
    /* ------------ WHAT IS DPP SECTION ------------ */
    /* --------------------------------------------- */

    .whatIsDpp-section {
        @extend .section;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        margin-top: 100px;

        .container {
            width: 50%;
            height: 50%;
            display: flex;
            position: relative;
        }

        .container-image {
            width: 90%;
            height: 50%;
            display: flex;
            position: relative;
            flex-direction: row-reverse;
        }

        .shadow-container {
            width: calc(90% - 10px);
            max-width: $img-max-width;
            max-height: $img-max-height;
            border: 5px solid $color-green;
            position: absolute;
            height: calc(100% - 10px);
            z-index: 1;
            margin-left: 10%;
            transform: translate(-10%, -10%);
        }

        img {
            width: 90%;
            max-width: $img-max-width;
            max-height: $img-max-height;
            display: block;
            position: relative;
            z-index: 1;
            padding-left: 10%;
        }

        .content-digital {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .digital-img {
                width: 90%;
                height: 90%;
            }

            button {
                width: auto;
                align-self: flex-start;
            }
        }
    }

    /* ----- END OF WHAT IS DPP SECTION ------- */

    /* --------------------------------------------- */
    /* ---------- WHY DPP MATTERS SECTION ---------- */
    /* --------------------------------------------- */

    .why-dpp-matters-section {
        @extend .section;

        .container {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .info-text {
            justify-content: center;
        }

        .sub-header {
            justify-content: center;
        }

        .desc {
            max-width: 887px;
        }

        .sections-container {
            margin-top: 50px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 2rem;
        }

        .image-container {
            text-align: center;
            color: #ffffff;
            width: 30%;

            .image-header {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
            }

            .verifierIcons {
                width: 96px;
                height: 96px;
            }
        }
    }
    /* ------- END OF WHY DPP MATTERS SECTION ---- ----*/

    /* ----------------------------------------------- */
    /* ---------- HOW DPP WORKS SECTION -------------- */
    /* ----------------------------------------------- */
    .how-dpp-works-section {
        @extend .section;
        display: flex;
        flex-direction: column;
        align-items: center;

        .container {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 8%;
            margin-top: 50px;
        }

        .info-text {
            justify-content: center;
        }

        .sub-header {
            justify-content: center;
        }

        .desc {
            max-width: 887px;
        }

        .sections-container {
            margin-top: 50px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 2rem;
        }

        .container-image {
            width: 100%;
            height: auto;
            display: flex;
            position: relative;
            flex-direction: row-reverse;
            margin-top: 5%;
        }

        .shadow-container {
            width: calc(95% - 10px);
            border: 5px solid $color-green;
            position: absolute;
            height: calc(100% - 10px);
            z-index: 1;
            transform: translate(-5%, -10%);
        }

        img {
            width: 95%;
            height: auto;
            display: block;
            position: relative;
            z-index: 1;
        }
    }

    /* ------- END OF HOW DPP WORKS SECTION --------- */

    /* ----------------------------------------------- */
    /* ---------- CORE FEATURES SECTION -------------- */
    /* ----------------------------------------------- */

    .core-features-section {
        @extend .section;
        display: flex;
        flex-direction: column;
        padding-bottom: 15%;

        .containers-container {
            display: flex;
            flex-direction: row;
            gap: 40px;
            height: $img-max-height;
            width: 100%;
        }

        .container {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .sub-header {
            justify-content: center;
            margin-bottom: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: $img-max-width;
            max-height: $img-max-height;
            display: block;
        }

        .shadow-container {
            width: 100%;
            height: 100%;
            max-width: calc($img-max-width - 10px);
            max-height: calc($img-max-height - 10px);
            border: 5px solid $color-green;
            position: absolute;
            bottom: -40px;
            left: -40px;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .core-features-panel {
            width: 100%;
            height: 100%;
            max-width: calc($img-max-width - 10px);
            max-height: calc($img-max-height - 10px);
            border: 5px solid #ffffff;
            background-color: $background-color;
            position: relative;
            z-index: 2;

            .panel-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .core-features-text {
                width: 90%;
            }

            img {
                width: 96px;
                height: 96px;
                padding: 30px;
            }
        }

        .shadow-container2 {
            @extend .shadow-container;
            bottom: 40px;
            left: 40px;
        }

        .core-features-panel2 {
            width: 100%;
            height: 100%;
            max-width: calc($img-max-width - 10px);
            max-height: calc($img-max-height - 10px);
            border: 5px solid #ffffff;
            background-color: $background-color;
            position: absolute; /* Position relative to the nearest positioned ancestor */
            color: #fff; /* Example text color for contrast */
            z-index: 2;

            .panel-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .core-features-text {
                width: 90%;
            }

            img {
                width: 96px;
                height: 96px;
                padding: 30px;
            }
        }
        .header {
            font-size: 30px;
            font-weight: 700;
            color: #ffffff;
        }

        .core-features-list {
            display: flex;
            flex-direction: column;

            .core-features-item {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .description {
                color: #ffffff;
            }

            img {
                width: 24px;
                height: 24px;
                padding: 5px;
            }
        }
    }

    /* ------- END OF CORE FEATURES SECTION -------- */

    /* --------------------------------------------- */
    /* ------------ DIGITAL DOC SECTION ------------ */
    /* --------------------------------------------- */

    .digital-doc-section {
        @extend .section;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        margin-top: 100px;

        .container {
            width: 50%;
            height: 50%;
            display: flex;
            position: relative;
        }

        .container-image {
            width: 90%;
            height: 50%;
            display: flex;
            position: relative;
            flex-direction: row-reverse;
        }

        .shadow-container {
            width: calc(90% - 10px);
            max-width: $img-max-width;
            max-height: $img-max-height;
            border: 5px solid $color-green;
            position: absolute;
            height: calc(100% - 10px);
            z-index: 1;
            margin-left: 10%;
            transform: translate(-10%, -10%);
        }

        img {
            width: 90%;
            max-width: $img-max-width;
            max-height: $img-max-height;
            display: block;
            position: relative;
            z-index: 1;
            padding-left: 10%;
        }

        .content-digital {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .digital-img {
                width: 90%;
                height: 90%;
            }

            button {
                width: auto;
                align-self: flex-start;
            }
        }
    }

    /* ----- END OF  DIGITAL DOC SECTION ------- */

    /* ----------------------------------------------- */
    /* ---------- HOW TO LEAD TO FUTURE SECTION ------ */
    /* ----------------------------------------------- */

    .howToLead-section {
        @extend .section;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .desc {
                max-width: 887px;
            }
        }
    }
    /* --- END OF HOW TO LEAD TO FUTURE SECTION ------ */

    /* ----------------------------------------------- */
    /* ----------------- PRICING SECTION ------------- */
    /* ----------------------------------------------- */

    .pricing-section {
        @extend .section;

        .header-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .logo {
            width: 100px;
            height: 132px;
        }

        .sub-header {
            justify-content: center;
            margin-bottom: 50px;
        }

        .pricing-content {
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .pricing-card {
                border: 5px solid $color-green;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 390px;
                height: 550px;
                margin: 3px;
            }
            .pricing-card-main {
                @extend .pricing-card;
                height: 630px;

                position: relative;

                .pricing-banner {
                    position: absolute;
                    top: -6px;
                    right: -8px;
                }
            }

            .pricing-card-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
                padding-bottom: 40px;
            }

            .pricing-card-content-main {
                @extend .pricing-card-content;
                padding-top: 40px;
            }

            .pricing-card-header {
                font-size: 30px;
                font-weight: 700;
                line-height: 45px;
                margin-bottom: 10px;
            }

            .pricing-card-description {
                text-align: center;
            }

            .pricing-card-price {
                color: $color-green;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                margin: 10px;
            }

            .pricing-card-features {
                margin-bottom: 16px;
            }

            .pricing-card-feature-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 2px;

                img {
                    padding: 5px;
                }
            }
        }
    }
    /* ------------ END OF PRICING SECTION ----------- */

    /* ----------------------------------------------- */
    /* ----------------- CONTACT SECTION ------------- */
    /* ----------------------------------------------- */

    .contact-section {
        @extend .section;

        .sub-header {
            justify-content: center;
            text-align: center;
        }
        .green {
            color: $color-green;
        }

        .small-header {
            @extend .sub-header;
            font-size: 30px;
        }

        .form-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .contact-form {
            color: #ffffff;
            display: flex;
            flex-direction: column;
            width: 40%;
            min-width: 400px;

            .label-text {
                padding-bottom: 8px;
            }

            input {
                background-color: $alternative-color;
                color: #ffffff;
                gap: 8px;
                padding: 16px 24px;
                font-size: 18px;
                line-height: 27px;
                font-family: "Poppins", sans-serif;
                border: none;
                margin-bottom: 20px;
            }

            textarea {
                background-color: $alternative-color;
                color: #ffffff;
                gap: 8px;
                padding: 16px 24px;
                font-size: 18px;
                line-height: 27px;
                font-family: "Poppins", sans-serif;
                border: none;
                margin-bottom: 20px;
                resize: vertical;
            }

            textarea:focus-visible,
            input:focus-visible {
                outline: none; /* Remove outline for focus-visible */
            }

            input:focus-visible {
                outline: none; /* Remove outline for focus-visible */
            }
        }
    }
    /* ------------ END OF CONTACT SECTION ----------- */

    // -------------------------------------------------//
    //----------- END OF DPP sections -------------//
    // -------------------------------------------------//
}
@media screen and (max-width: 1023px){
    .dpp-theme{
        // Core features
        .core-features-section {
            .sub-header {
                margin-bottom: 40px;
            }

            .containers-container {
                flex-direction: column;
                height: auto;
                gap: 60px;

                .container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                    max-width: 700px;

                    .core-features-panel {
                        border: 3px solid #ffffff;
                        width: 90%;
                        height: auto;
                        max-height: none;

                        img {
                            padding: 20px 0 10px 0;
                        }

                        .core-features-text {
                            width: 70%;

                            .header {
                                font-size: 23px;
                                line-height: 34.5px;
                                margin: 0;
                            }
                        }

                        .core-features-list {
                            padding-bottom: 20px;
                            .core-features-item {
                                .description {
                                    font-size: 15px;
                                    line-height: 22.5px;
                                    margin: 5px 0;
                                }
                            }
                        }
                    }

                    .shadow-container {
                        width: 90%;
                        top: -3vw;
                        left: 0.5vw;
                    }

                    .shadow-container2 {
                        width: 90%;
                        top: -3vw;
                        right: 0.5vw;
                        left: unset;
                    }

                    .core-features-panel2 {
                        border: 3px solid #ffffff;
                        width: 90%;
                        height: auto;
                        max-height: none;
                        position: unset;

                        img {
                            padding: 20px 0 10px 0;
                        }

                        .core-features-text {
                            width: 70%;

                            .header {
                                font-size: 23px;
                                line-height: 34.5px;
                                margin: 0;
                            }
                        }

                        .core-features-list {
                            padding-bottom: 20px;
                            .core-features-item {
                                .description {
                                    font-size: 15px;
                                    line-height: 22.5px;
                                    margin: 5px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .dpp-theme {
        // Navigation
        .navbar {
            height: 125px;
            padding: 0;
            //padding: 10px 0px 30px 0px;
            .logo {
                width: 70px;
                height: 93px;
            }
        }

        .sidebar {
            min-width: unset;
            width: 100%;
            padding: 0;

            .close-btn {
                top: 5%;
            }

            .links {
                gap: 0;
                width: calc(100% - 32px);
                padding: 0 16px 0 16px;
                word-break: break-all;

                a {
                    font-size: 34px;
                    line-height: 51px;
                }
            }

            .graphic {
                img {
                    bottom: 5%;
                }
            }
        }

        // Footer
        .footer-section {
            padding: 15% 0;

            .footer-links {
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: center;
                padding-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dpp-theme {
        .scroller {
            overflow: auto;
        }

        .scroller__inner {
            flex-wrap: nowrap;
        }

        .m-show {
            display: block;
        }

        .m-hide {
            display: none;
        }

        .btn-mynextid {
            width: 100%;
        }

        .shadow-container {
            border: 3px solid $color-green !important;
        }

        // HOME
        .home-section {
            flex-direction: column;
            align-items: center;

            .home-text {
                width: 100%;

                .home-text-el {
                    font-size: 40px;
                    line-height: 60px;
                }
            }

            .home-bg {
                position: unset;
                width: 100%;
                padding: 10px 0;
            }

            .home-text-underline {
                box-shadow: inset 0px -22px 0px 0px $alternative-color;
            }

            .home-text-underline-main {
                box-shadow: inset 0px -22px 0px 0px var(--theme-color);
            }

            .home-link {
                width: 100%;
                //max-width: 310px;
            }
        }

        .info-text {
            font-size: 17px;
            line-height: 25.5px;
        }

        .sub-header {
            font-size: 35px;
            line-height: 52.5px;
        }

        .desc {
            font-size: 15px;
            line-height: 22.5px;
            max-width: none !important;
        }

        // What is dpp
        // What is digital doc
        .whatIsDpp-section,
        .digital-doc-section {
            flex-direction: column;
            padding-top: 10%;
            gap: 0;

            .container {
                width: 100%;
            }

            .content-digital {
                margin-top: 2rem;
                width: 90%;
            }
        }

        // Why dpp matters
        .why-dpp-matters-section {
            padding-top: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .container {
                width: 90%;
            }

            .sections-container {
                flex-direction: column;
                align-items: center;

                .image-container {
                    width: 90%;
                }

                .image-header {
                    font-size: 19px;
                    line-height: 28.5px;
                }

                .image-desc {
                    font-size: 15px;
                    line-height: 22.5px;
                }
            }
        }

        // How dpp works
        .how-dpp-works-section {
            padding-top: 8%;

            .shadow-image-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .shadow-img {
                    width: 95%;
                    //max-width: 327px;
                    //padding-left: 5%;
                }

                .shadow-container {
                    width: 95%;
                    bottom: 16px;
                    left: 0;
                }
            }
        }

        .contact-section {
            .contact-form {
                min-width: unset;
                width: 100%;
            }

            .small-header {
                font-size: 23px;
                line-height: 34.5px;
            }
        }
    }
}
