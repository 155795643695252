/* --------------------------------------------- */
/* -------------- DEFAULT VALUES --------------- */
/* --------------------------------------------- */

$sections-width: 90%;
$background-color: #010b48;
$alternative-color: #343c6d;
$white-color: #f4f4f4;
$color-green: hsla(162, 98%, 48%, 0.9);
$color-yellow: #ffba34;
$color-purple: #8c72f7;
$secondary-purple: #5965b0;
$img-max-width: 608px;
$img-max-height: 608px;

/* --------- ENF OF DEFAULT VALUES ------------- */

/* --------------------------------------------- */
/* --------------- BASIC  THEME ---------------- */
/* --------------------------------------------- */

body {
    font-family: "Poppins-bold", sans-serif;

    p {
        font-family: "Poppins-regular", sans-serif;
    }

    /*--- SCROLLBAR ---*/
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $background-color; /* Background of the scrollbar track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: $alternative-color; /* Color of the scrollbar thumb */
        border-radius: 12px; /* Roundness of the scrollbar thumb */
        cursor: pointer;
    }
}

button {
    font-family: "Poppins-bold", sans-serif;
}

.main-theme {
    background-color: $background-color;
    width: 100%;
    height: 100%;
}

/*--- FONTS ---*/
@font-face {
    font-family: "Poppins-regular";
    src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-bold";
    src: url("../fonts/Poppins-Bold.ttf");
}

/*--- DEFAULT FONT SIZE ---*/

.desc {
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    color: #f4f4f4;
    flex: none;
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
}

.header {
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;

    font-weight: 700;
    font-size: 64px;
    line-height: 96px;
    display: flex;
    align-items: center;

    color: #ffffff;
}

.sub-header {
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;

    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    align-items: center;

    color: #ffffff;
}

.small-sub-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;

    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;

    color: #ffffff;
}

.smaller-sub-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;

    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;

    color: #ffffff;
}

.info-text {
    font-weight: 700;
    flex: none;
    flex-grow: 0;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    flex-grow: 0;
}

.smaller-info-text {
    font-weight: 700;
    flex: none;
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    flex-grow: 0;
}

.m-show {
    display: none;
}

.m-hide {
    display: block;
}


/* ------------- END OF BASIC THEME ------------ */

/* --------------------------------------------- */
/* ------------------- BUTTON ------------------ */
/* --------------------------------------------- */

.btn-mynextid {
    background-color: white;
    color: #010b48;
    font-size: 20px;
    font-weight: 700;
    min-width: 150px;
    min-height: 52px;
    border: 1px solid #231f20;
    cursor: pointer;
    margin-top: 10px;
    height: auto;
    width: auto;
    padding: 12px 15px;
}

.btn-mynextid:hover {
    background-color: #525c9c !important;
    color: #ffffff !important;
}

/* --------------- END OF BUTTON --------------- */

/* --------------------------------------------- */
/* ------------------ SECTIONS ----------------- */
/* --------------------------------------------- */

.section {
    width: $sections-width;
    max-width: 1248px;
    margin: auto;
    padding-bottom: 8%;
    color: #ffffff;
}

/* --------------------------------------------- */
/* ------------ MAIN TITLE SHADOW -------------- */
/* --------------------------------------------- */

// Box shadow for main titles
.shadow-title {
    box-shadow: inset 0px -15px 0px 0px var(--theme-color);
}

/* Container width*/
.w-50 {
    width: 50%;
    min-width: 310px;
    //outline: 1px solid red;
    //background-color: rgba(255, 255, 255, 0.4)
}

/* ELEMENTS */
.left-element {
    width: 90%;
    height: 90%;
    z-index: 0;
}

/* --------- END OF MAIN TITLE SHADOW --------- */

/* --------------------------------------------- */
/* ---------- SMOOTH TEXT TRANSITION ----------- */
/* --------------------------------------------- */

.text {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* ------- END OF SMOOTH TEXT TRANSITION -------- */

/* --------------------------------------------- */
/* -------------- MEDIA VIEW  ------------------ */
/* --------------------------------------------- */
@media screen and (max-width: 768px) {
    // Change all main font-sizes & line-heights
    .info-text {
        font-size: 17px;
        line-height: 25px;
    }

    .header {
        font-size: 40px;
        line-height: 60px;
    }

    .sub-header {
        font-size: 35px;
        line-height: 52px;
    }

    .desc {
        font-size: 15px;
        line-height: 22px;
    }

    .smaller-info-text {
        font-size: 16px;
        line-height: 24px;
    }

    .small-sub-header {
        font-size: 23px;
        line-height: 34px;
    }

    .smaller-sub-header {
        font-size: 19px;
        line-height: 28px;
    }
}
